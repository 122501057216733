import { idTokenFromLocalStorage, idTokenFromSessionStorage } from "@/Utils/config";
import axiosMethods from "../https/https";
import {
  ReferInterviewerParametersType,
  RoleResponseCompanyType,
  UserByRoleType,
} from "@/Types/Profile/editProfileTypes";
import catchAsync from "@/Utils/catchAsync";

// Update basic info of the current role
/* eslint-disable @typescript-eslint/no-explicit-any */

export const updateProfileDetailsApi = async (values: any) => {
  const update = await axiosMethods.post(`/user-profile`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return update;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

// Get list of skills for the current role
/* eslint-disable @typescript-eslint/no-explicit-any */

export const skillsListApi = async (values: any) => {
  const update = await axiosMethods.post(
    `/user-profile`,
    {},
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role ?? "Default",
      },
    }
  );
  return update;
};

export const updateAccountSettings = async (values: any) => {
  const update = await axiosMethods.post(`/user-profile`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return update;
};

export const deleteFileFromS3ForProfile = async (values: any) => {
  const update = await axiosMethods.post(`/user-profile`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return update;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const switchRoleApi = async (values: any) => {
  const update = await axiosMethods.post(`/user-profile`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.updatedRole,
    },
  });
  return update;
};

// Update Interviwer profile

/* eslint-disable @typescript-eslint/no-explicit-any */

export const updateProfileDetailsInterviwerApi = async (values: any) => {
  const update = await axiosMethods.post(`/interviewer`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return update;
};
export const addNewSkillsApi = async (values: any) => {
  const update = await axiosMethods.post(
    `/user-profile`,
    { skills: values.bodyDataForAddSkills.skills },
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return update;
};

export const getRequestedSkillsForInterViewerApi = async (values: any) => {
  const requestedSkillList = await axiosMethods.post(
    `/interviewer`,
    {},
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: "interviewer-requested-skills",
        ProfileRole: values?.role,
      },
    }
  );
  return requestedSkillList;
};
export const updateRequestedSkillsApi = async (values: any) => {
  const update = await axiosMethods.post(`/interviewer`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: "interviewer-skill-update-request",
      ProfileRole: values?.role,
    },
  });
  return update;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * @description Track user activity from FE
 * @bodyData {
    "user":"string",
    "roleProfileId":"string",
    "eventType":"string"
}
 */

export const trackUserActivity = async (values: any) => {
  //eslint-disable-line
  const update = await axiosMethods.post(`/user-profile`, values.bodyData, {
    headers: {
      Resource: "track-user-activity",
      ProfileRole: values?.role,
    },
  });
  return update;
};

/**
 * @description Refer an user to become an interviwer
 * @bodyData {
    "email":"string",
}
 */

export const referToBecomeAnInterviewer = async (values: ReferInterviewerParametersType) => {
  const update: { code: number } = await axiosMethods.post(`/interviewer`, values.bodyData, {
    headers: {
      Resource: "send-referral-code",
    },
  });
  if (update.code === 200) {
    return update;
  }
};

/**
 * @description Refer an user to become an interviwer
 * @bodyData {
    "profileType":"string",
    "companyId":"string",
}
 */

export const getUserForSelectedRole = async (values: UserByRoleType) => {
  const update: RoleResponseCompanyType = await axiosMethods.post(`/company`, values.bodyData, {
    headers: {
      Resource: "user-list-without-pg",
    },
  });
  if (update.code === 200) {
    return update;
  }
};

// Update basic info of the current company
export const updateCompanyProfileDetailsApi = async (values: any) => {
  // eslint-disable-line
  const update = await axiosMethods.post(`/company`, values.bodyData, {
    headers: {
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return update;
};

/**
 * Delete interviewer profile
 */

export const deleteInterViwerProfileApi = catchAsync(async () => {
  const response = await axiosMethods.post(
    `/interviewer`,
    {},
    {
      headers: {
        Resource: "interviewer-profile-delete",
      },
    }
  );
  return response;
});

/**
 * Delete Company profile
 */

export const deleteCompanyProfileApi = catchAsync(async (id: string) => {
  const response = await axiosMethods.post(
    `/company`,
    {
      companyId: id,
    },
    {
      headers: {
        Resource: "company-page-delete",
      },
    }
  );
  return response;
});
/**
 * Delete Default profile
 */

export const deleteDefaultProfileApi = catchAsync(async (bodyData) => {
  const response = await axiosMethods.post(`/user-profile`, bodyData, {
    headers: {
      Resource: "delete-user-account",
    },
  });
  return response;
});
// Deactive InterViwer profile
export const deactiveInterviwerProfileApi = catchAsync(async () => {
  const response = await axiosMethods.post(
    `/interviewer`,
    {},
    {
      headers: {
        Resource: "interviewer-profile-deactive",
      },
    }
  );
  return response;
});

// Deactive Company profile
export const deactiveCompanyProfileApi = catchAsync(async (id: string) => {
  const response = await axiosMethods.post(
    `/company`,
    {
      companyId: id,
    },
    {
      headers: {
        Resource: "company-page-deactive",
      },
    }
  );
  return response;
});
// Deactive Default profile
export const deactiveDefaultProfileApi = catchAsync(async () => {
  const response = await axiosMethods.post(
    `/user-profile`,
    {},
    {
      headers: {
        Resource: "deactive-user-account",
      },
    }
  );
  return response;
});
// Reactive Interviewer profile
export const reactiveInterviewerProfileApi = catchAsync(async () => {
  const response = await axiosMethods.post(
    `/interviewer`,
    {},
    {
      headers: {
        Resource: "interviewer-profile-reactive",
      },
    }
  );
  return response;
});
// Reactive Company profile
export const reactiveCompanyProfileApi = catchAsync(async (id: string) => {
  const response = await axiosMethods.post(
    `/company`,
    {
      companyId: id,
    },
    {
      headers: {
        Resource: "company-page-reactive",
      },
    }
  );
  return response;
});
