import catchAsync from "@/Utils/catchAsync";
import axiosMethods from "../https/https";
import { idTokenFromLocalStorage, idTokenFromSessionStorage } from "@/Utils/config";
import { dataURLtoFile } from "@/Utils/CommonFuncs";

export const getS3SignUrl = catchAsync(async (values) => {
  const data = await axiosMethods.post(`/user-profile`, values.dataFiles, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: "upload-file-pre-signed",
      ProfileRole: values?.role,
    },
  });

  return data;
});
/* eslint-disable @typescript-eslint/no-explicit-any */

export const pushToS3Api = async (values: any) => {
  const myHeaders = new Headers({
    "Content-Type": values?.type?.includes("image") ? "image/*" : values?.type?.includes("video") ? "video/*" : "",
  });

  const blob = dataURLtoFile(values.file, values.name);
  try {
    const response = await fetch(values?.link, {
      method: "PUT",
      headers: myHeaders,
      body: blob,
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
/* eslint-enable @typescript-eslint/no-explicit-any */
/**
 * Get new s3 file url for existing file
 */
export const getNewS3Url = async ({ bodyData }: any) => {
  //eslint-disable-line
  try {
    const newUrl: { url: string } = await axiosMethods.post(`/job-apply`, bodyData, {
      headers: {
        Resource: "existing-application-file-upload",
      },
    });
    return newUrl;
  } catch (err) {
    return err;
  }
};

//presigned url for interview record
export const getS3SignUrlForInterviewRecord: any = catchAsync(async (values) => {
  // eslint-disable-line
  const data = await axiosMethods.post(`/interview`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: "get-session-presigned",
      ProfileRole: values?.role,
    },
  });

  return data;
});
export const getS3SignUrlChatRoom = catchAsync(async (values) => {
  const data = await axiosMethods.post(`/interview`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: "chatroom-file-pre-signed",
      ProfileRole: values?.role,
    },
  });

  return data;
});
