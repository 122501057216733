import { getRequestedSkillsForInterViewerApi, updateRequestedSkillsApi } from "@/Services/apis/profile.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState: any = {
  // eslint-disable-line
  requestedSkill: {},
};

export const gteRequestedSkills = createAsyncThunk("interviewerSignup", async (values: any, { rejectWithValue }) => {
  // eslint-disable-line
  try {
    const gteRequestedSkillsRes: any = await getRequestedSkillsForInterViewerApi(values); // eslint-disable-line
    if (gteRequestedSkillsRes?.code !== 200) return rejectWithValue(gteRequestedSkillsRes);
    return gteRequestedSkillsRes;
  } catch (error: any) {
    // eslint-disable-line
    // values?.router && values?.router?.replace("/feeds");

    return rejectWithValue(error.response.data);
  }
});

export const updateInterViewerProfileRequestedSkills = createAsyncThunk(
  "updateInterViewerProfile",
  async ({ values, setOpenSkillModelInterviewer }: any, { rejectWithValue }) => {
    // eslint-disable-line
    try {
      const updateprofileDetails: any = await updateRequestedSkillsApi(values); // eslint-disable-line
      if (updateprofileDetails?.code !== 200) return rejectWithValue(updateprofileDetails);
      if (updateprofileDetails?.code === 200) {
        values.dispatch(gteRequestedSkills({ profileRole: values.role }));
        setOpenSkillModelInterviewer(true);
        toast.success(updateprofileDetails?.message);
      }
      return updateprofileDetails;
    } catch (error: any) {
      // eslint-disable-line
      return rejectWithValue(error.response.data);
    }
  }
);

export const interviewerSlice = createSlice({
  name: "interviewerSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setQequestedSkill: (state, action) => {
      state.requestedSkill = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gteRequestedSkills.fulfilled, (state, action) => {
      state.requestedSkill = action.payload.data;
    });
  },
});
export const { setQequestedSkill } = interviewerSlice.actions;

export default interviewerSlice.reducer;
