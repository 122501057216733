import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState: any = {
  //eslint-disable-line
  abilities: [],
};

export const canAbilitiesStore = createSlice({
  name: "canAbilitiesStore",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAbilities: (state, action) => {
      state.abilities = action?.payload;
    },
  },

  extraReducers: (builder) => {
    builder;
  },
});
/* eslint-disable no-empty-pattern */
export const { setAbilities } = canAbilitiesStore.actions;
/* eslint-enable no-empty-pattern */

export default canAbilitiesStore.reducer;
