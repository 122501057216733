import { EmailCheckType } from "@/Types/Auth/AuthType";
import { isRememberMeFromLocalStorage } from "@/Utils/config";
import { getSessionService } from "../https/https";
import { CognitoUser } from "amazon-cognito-identity-js";
import { cognitoConfig } from "@/Utils/CognitoConfig";
import axios, { AxiosError } from "axios";
import { getUserData, handleSignOut } from "@/Utils/cognito-auth-functions";
import { ContactUsApiType } from "@/Types/FormTypes";

/**
 * Refresh token handle when current logged in user is unauthenticated
 *
 */
export const refreshTokenService = async (userName: string, error: AxiosError) => {
  try {
    const cognitoUserSession = await getSessionService();
    const refreshToken = cognitoUserSession.getRefreshToken();
    const cognitoUser = new CognitoUser({
      Username: userName,
      Pool: cognitoConfig,
    });
    cognitoUser.refreshSession(refreshToken, async (err, result) => {
      if (err) {
        return err;
      }
      if (isRememberMeFromLocalStorage && result && error.config) {
        localStorage.setItem("accessToken", result.accessToken.jwtToken);
        localStorage.setItem("idToken", result.idToken.jwtToken);
        localStorage.setItem("refreshToken", result.refreshToken.token);
        if (error.config?.data) {
          error.config.data = JSON.parse(error.config.data);
        }
        error.config.headers["Authorization"] = `${result.idToken.jwtToken}`;
        error.config.baseURL = undefined;
        return await axios.request(error.config);
      } else if (result && error.config) {
        sessionStorage.setItem("accessToken", result.accessToken.jwtToken);
        sessionStorage.setItem("idToken", result.idToken.jwtToken);
        sessionStorage.setItem("refreshToken", result.refreshToken.token);
        if (error.config?.data) {
          error.config.data = JSON.parse(error.config.data);
        }
        error.config.headers["Authorization"] = `${result.idToken.jwtToken}`;
        error.config.baseURL = undefined;
        return await axios.request(error.config);
      }
      return result;
    });
  } catch (err) {
    return err;
  }
};
//email checking that it is the registered email or not
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * 
 * @param bodyData {
  userName: string;
  credType: string;
}
 * @description this api will check if any user is registered with this account
 */
export const emailCheckApi = async (bodyData: EmailCheckType) => {
  const checkEmail = await axios.post(`${process.env.NEXT_PUBLIC_API_URL_WITHOUT_AUTH}`, bodyData, {
    headers: {
      Resource: "forget-password-user-check",
      ProfileRole: "Default",
    },
  });
  return checkEmail as { code?: number };
};
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Logout from local and clear cognito user from cookies also
 */
export const handleLogout = async () => {
  const cognitoUser = await getUserData();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = "/auth/login";
  if (cognitoUser) {
    handleSignOut();
  }
};

//Contact form registration
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * 
 * @param bodyData {
  name: string,
  email: string,
  phone: string,
  subject: string,
  message: string,
}
 * @description Contact us form APi call
 */

export const contactFormApi = async (bodyData: ContactUsApiType) => {
  const contactFormData = await axios.post(`${process.env.NEXT_PUBLIC_API_URL_WITHOUT_AUTH}`, bodyData, {
    headers: {
      Resource: "contact-us",
    },
  });
  return contactFormData as { code?: number; message?: string };
};
/* eslint-enable @typescript-eslint/no-explicit-any */
