// import { RootState } from "@/Redux/store";
import { getLocalstorageData, getSessionstorageData } from "./CommonFuncs";

export const storeKey = "og-web";
export const persistKeys = {
  userProfileSlice: "userProfile",
  applicantsCardSlice: "applicantsCardReducer",
};

export const idTokenFromLocalStorage = getLocalstorageData("idToken");
export const idTokenFromSessionStorage = getSessionstorageData("idToken");
export const currentDashboardRole = getLocalstorageData("currentDashboardRole");
export const refreshTokenTokenFromLocalStorage = getLocalstorageData("refreshToken");
export const refreshTokenFromSessionStorage = getSessionstorageData("refreshToken");
export const getEmailFromLocalStorage = getLocalstorageData("email");
export const getUserNameFromLocalStorage = getLocalstorageData("userName");
export const isRememberMeFromLocalStorage = getLocalstorageData("isRememberMe");
export const getUserFromLoacalStorage = () => {
  let localstore;
  if (getLocalstorageData("persist:og-web")) {
    localstore = getLocalstorageData("persist:og-web") && JSON?.parse(getLocalstorageData("persist:og-web"));
  }
  // Merge InitialState with stuff in localstore
  return localstore?.userProfile && JSON.parse(localstore?.userProfile);
};

// export const getReduxState: () => RootState = () =>require('@/Redux/store') && require('@/Redux/store')?.default &&  require('@/Redux/store')?.default.getState();
