import { CanType } from "@/Types/PropsPassTypes/PropsTypes";
import { getLocalstorageData } from "../CommonFuncs";

export const getAbilities = (currentRole?: string) => {
  let abilities: CanType[] = [];
  if (getLocalstorageData("currentDashboardRole") === "Default" || currentRole === "Default") {
    abilities = [
      // Edit Profile
      {
        can: "view",
        resourse: "EditProfileBasicInfo",
      },
      {
        can: "view",
        resourse: "EditProfileAboutMe",
      },
      {
        can: "view",
        resourse: "EditProfileEducation",
      },
      {
        can: "view",
        resourse: "EditProfileWorkExperience",
      },
      {
        can: "view",
        resourse: "EditProfileSkills",
      },
      {
        can: "view",
        resourse: "EditProfileCertificates",
      },

      {
        can: "view",
        resourse: "EditProfileResumeCoverLetter",
      },
      {
        can: "view",
        resourse: "EditProfileDefault",
      },
      //
      {
        can: "create",
        resourse: "JobCreate",
      },
      {
        can: "view",
        resourse: "MyJobs",
      },
      {
        can: "create",
        resourse: "CompanyCreate",
      },
      {
        can: "view",
        resourse: "AccountSettings",
      },
      {
        can: "view",
        resourse: "ChangePassword",
      },
      //view profile
      {
        can: "create",
        resourse: "ViewProfileDefault",
      },
      //Feed section
      {
        can: "create",
        resourse: "FeedSection",
      },
      //Interviews section
      {
        can: "create",
        resourse: "DefaultRoleInterviewSection",
      },
      // Layout Renderer
      {
        can: "view",
        resourse: "UserLayoutRenderer",
      },
      {
        can: "view",
        resourse: "EditProfile",
      },
      {
        can: "view",
        resourse: "EditAccountSettings",
      },
      {
        can: "view",
        resourse: "DeactiveDefault",
      },
      {
        can: "view",
        resourse: "DeleteDefault",
      },
      //Payment
      {
        can: "view",
        resourse: "PaymentRecruiter",
      },
      {
        can: "view",
        resourse: "CompanyInvitations",
      },
    ];
  } else if (getLocalstorageData("currentDashboardRole") === "Interviewer" || currentRole === "Interviewer") {
    abilities = [
      // Edit Profile
      {
        can: "view",
        resourse: "EditProfileBasicInfo",
      },
      {
        can: "view",
        resourse: "EditProfileAboutMe",
      },
      {
        can: "view",
        resourse: "EditProfileSkills",
      },
      {
        can: "view",
        resourse: "EditProfileInterviewer",
      },
      //
      {
        can: "create",
        resourse: "CompanyCreate",
      },
      //Interviews section
      {
        can: "create",
        resourse: "InterviewerRoleInterviewSection",
      },

      //view profile
      {
        can: "create",
        resourse: "ViewProfileInterviewer",
      },
      // Layout Renderer
      {
        can: "view",
        resourse: "UserLayoutRenderer",
      },
      {
        can: "view",
        resourse: "InterViewDelete",
      },
      {
        can: "view",
        resourse: "DeactiveInterviewer",
      },
      //Payment
      {
        can: "view",
        resourse: "PaymentInterviewer",
      },
    ];
  } else if (getLocalstorageData("currentDashboardRole") === "Company Owner" || currentRole === "Company Owner") {
    abilities = [
      {
        can: "create",
        resourse: "JobCreate",
      },

      //View profile
      {
        can: "create",
        resourse: "ViewProfileCompany",
      },
      //Edit profile

      {
        can: "view",
        resourse: "EditProfileCompany",
      },
      //Feed section
      {
        can: "create",
        resourse: "FeedSection",
      },
      // Layout Renderer
      {
        can: "view",
        resourse: "UserLayoutRenderer",
      },
      {
        can: "view",
        resourse: "DeleteCompany",
      },
      {
        can: "view",
        resourse: "DeactiveCompany",
      },
      {
        can: "view",
        resourse: "AdminControlUser",
      },
      //Payment
      {
        can: "view",
        resourse: "PaymentRecruiter",
      },
    ];
  }
  return abilities;
};
